import axios from "axios";

const API_URL = "https://survey.myavana.com";

const notifyAlert = (data) => {
  if(data.firstName.length > 0 && data.lastname.length > 0 && data.emailAddress.length > 0)
  {
    return axios.post("https://hooks.slack.com/services/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",  {"text": `${data.firstName} ` + `${data.lastName} ` + `${`Just signed up! their email is ` + data.emailAddress}`} , {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });
  }
};

const userQuestionAdd = (data) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(API_URL + "/api/Profile/profile", data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        console.log(result);
        notifyAlert(data);
        resolve(result);
      })
      .catch((ex) => reject(ex.message));
  });
};

export default { userQuestionAdd };
