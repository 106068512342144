import { USER_INPUT, USER_INPUT_ERROR } from "../actions/types";

const initialState = {};

export default function userInput(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_INPUT:
      return payload;
    case USER_INPUT_ERROR:
      return payload;
    default:
      return state;
  }
}
