import axios from "axios";
import { DataURIToBlob } from "./blobConverter";

export function classifyImage(data) {
  const formData = new FormData();
  formData.append("imageFile", DataURIToBlob(data));
  //for directories you can read the filestream instead fs.createReadStream(data)
  return new Promise(function (resolve, reject) {
    axios
      .post("https://analysisai01.myavana.com/api/Image/classifyimage", formData, {
        headers: {
          "api-key": "5edefd9e-7f1b-44c1-9dca-1c9df818664a",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((result) => {
        resolve(result);
      })
      .catch((ex) => reject(ex.message));
  });
}
