import { USER_INPUT, USER_INPUT_ERROR } from "./types";
import UserInputService from "../services/userInput.service";

export const createUserAction = (data) => async (dispatch) => {
  try {
    const res = await UserInputService.userQuestionAdd(data);
    dispatch({
      type: USER_INPUT,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch({
      type: USER_INPUT_ERROR,
      payload: err,
    });
    return Promise.reject(err);
  }
};