import { CLASSIFY_IMAGE } from "../actions/types";

const initialState = {};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CLASSIFY_IMAGE:
      return payload;

    default:
      return state;
  }
}