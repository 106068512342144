import { Transition } from "@headlessui/react";
// import { useState } from "react";

export default function Fader(props) {
//  No need for a useState here since this is a fadeIn component and will be rendered on first render. Deprecate on the next version.
//  const [isShowing, setIsShowing] = useState(true);
  return (
    <Transition className="flex flex-1 justify-center" show={true} appear={true} enter="transition-opacity duration-500" enterFrom="opacity-0" enterTo="opacity-100" leave="transition-opacity duration-500" leaveFrom="opacity-100" leaveTo="opacity-0">
      {props.children}
    </Transition>
  );
}
