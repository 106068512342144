import bronn from "./img/kinksandcurlsatl.png";
import poweredBy from "./img/poweredby.png";
import "./App.css";
import { BackspaceIcon } from "@heroicons/react/solid";
import { useState, useCallback, useEffect } from "react";
import { Tabs, TabPanel } from "react-tabs";
import Fader from "./components/fader";
import Webcam from "react-webcam";
import SampleImg from "./img/sampleImg.png";
import PlaceHolder from "./img/placeHolder.png";
import { classifyImage } from "./helpers/api";
import productsCSV from "./data/products_01.json";

import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { createUserAction } from "./actions/userDetails";

function App() {
  const dispatch = useDispatch();

  const [setIndex, setSetIndex] = useState(0);

  const [devices, setDevices] = useState([]);
  const [imageX, setImageX] = useState("");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [explainer, setExplainer] = useState({
    label: "For coarse textured hair, add hydrating properties with moisturizing shampoo. Hydrate for maximum manageability with steam therapy; protective styles are excellent for coarse textures length retention. Coils are easy to control with a curl enhancing mousse.  Detangle with lightweight conditioners.",
    labelValue: "Type4c",
    score: {
      "Type 4c": 99.30145,
      NoHair: 0.32344967,
      "Type 4b": 0.32167011,
    },
    hairTypeLabel: "Coily/Kinky, 4C",
    hairTextureLabel: "Coarse",
  });

  const [videoConstraints, setVideoConstraints] = useState({
    width: 350,
    height: 500,
    facingMode: "user",
    //deviceId: devices[0].deviceId
  });

  const handleDevices = useCallback(
    (mediaDevices) => {
      setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput"));
    },
    [setDevices]
  );

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  function tabTo(index) {
    if (index === 1) {
      setVideoConstraints({
        width: 350,
        height: 350,
        // facingMode: "user",
        //change to selectable device id later
        deviceId: devices[0].deviceId,
      });
    }
    setSetIndex(index);
  }

  //firstCall Variable call.
  var userJson = {
    firstName: "string",
    lastName: "string",
    emailAddress: "string",
    dateofBirth: "2022-08-25T14:54:17.288Z",
    zipCode: "string",
  };

  //secondCall Variable call.
  var userQPostData = {
    email: "string",
    inputRequests: [
      {
        questionId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        optionId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      },
    ],
  };

  return (
    <div className="App">
      <div className="fillPage container mx-auto py-10">
        <header className="h-16 justify-start flex items-center px-2">
          <button onClick={() => tabTo(setIndex === 0 ? 0 : setIndex - 1)} className="flex flex-row items-center bg-black bg-opacity-5 hover:text-black text-black py-2 px-4 rounded-xl cursor-pointer">
            <BackspaceIcon className="pr-2 h-4" />
            Back
          </button>
        </header>
        <button className="">
          <img src={bronn} className="h-10 lg:h-16 mb-10 mx-auto" />
        </button>
        {/* <div className="justify-center text-lg lg:text-2xl font-face-jouls">Step One</div> */}
        <div className="justify-center font-semibold text-xl lg:text-4xl">Welcome To Our Personalized Hair Care Experience!</div>
        <div className="flex flex-1 bg-pink-100 mt-10">
          <div className="flex flex-1 container mx-auto">
            <Tabs selectedIndex={setIndex}>
              <TabPanel>
                <Fader>
                  <div className="flex flex-1 flex-grow h-full">
                    <div className="pt-8 mx-auto">
                      <div>
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Let's get started with some basic info</h3>
                        <form onSubmit={() => tabTo(1)} className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                          <div className="sm:col-span-3">
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                              First name
                            </label>
                            <div className="mt-1">
                              <input required onChange={(element) => (userJson.firstName = element.target.value)} type="text" name="first-name" id="first-name" autoComplete="given-name" className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                            </div>
                          </div>
                          <div className="sm:col-span-3">
                            <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                              Last name
                            </label>
                            <div className="mt-1">
                              <input required onChange={(element) => (userJson.lastName = element.target.value)} type="text" name="last-name" id="last-name" autoComplete="family-name" className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                            </div>
                          </div>
                          <div className="sm:col-span-6">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                              Email address
                            </label>
                            <div className="mt-1">
                              <input required onChange={(element) => (userJson.emailAddress = element.target.value)} id="email" name="email" type="email" autoComplete="email" className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                            </div>
                          </div>
                          <div className="sm:col-span-6">
                            <label htmlFor="bday" className="block text-sm font-medium text-gray-700">
                              Birthday
                            </label>
                            <div className="mt-1">
                              <input required onChange={(element) => (userJson.dateofBirth = new Date(element.target.value).toISOString())} id="bday" name="bday" type="date" autoComplete="bday" className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                            </div>
                          </div>
                          <div className="sm:col-span-6">
                            <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                              Zip Code
                            </label>
                            <div className="mt-1">
                              <input required onChange={(element) => (userJson.zipCode = element.target.value)} id="postal-code" name="postal-code" type="text" pattern="[0-9]{5}" autoComplete="postal-code" className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                            </div>
                          </div>
                          <div className="sm:col-span-6 mb-8">
                            <button type="submit" onClick={() => dispatch(createUserAction(userJson))} className="bg-black hover:text-gray-200 text-white py-2 px-4 rounded-xl cursor-pointer w-full">
                              Next
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </Fader>
              </TabPanel>
              <TabPanel>
                <Fader>
                  <form onSubmit={() => console.log("submitting...")}>
                    <fieldset className="flex items-start flex-col py-10">
                      <legend className="sr-only text-xl">What are your top 3 hair challenges?</legend>
                      <div className="text-base font-medium text-gray-900" aria-hidden="true">
                        What are your top 3 hair challenges?
                      </div>
                      <div className="mt-4 space-y-4 flex flex-col">
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input id="comments" name="comments" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                          </div>
                          <div className="ml-3 text-sm flex flex-col items-start">
                            <label htmlFor="comments" className="font-medium text-gray-700">
                              Dryness
                            </label>
                            <p className="text-gray-500">Experiencing dryness in your hair, scalp, or both. This can be caused by a number of factors including weather, product build-up, or over-washing.</p>
                          </div>
                        </div>
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input id="candidates" name="candidates" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                          </div>
                          <div className="ml-3 text-sm flex flex-col items-start">
                            <label htmlFor="candidates" className="font-medium text-gray-700">
                              Product Buildup
                            </label>
                            <p className="text-gray-500">Too much product build-up on your hair can weigh it down, making it look greasy and lifeless. It can also lead to scalp issues like dandruff.</p>
                          </div>
                        </div>
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input id="offers" name="offers" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                          </div>
                          <div className="ml-3 text-sm flex flex-col items-start">
                            <label htmlFor="offers" className="font-medium text-gray-700">
                              Oily hair
                            </label>
                            <p className="text-gray-500">Having an oily scalp can be frustrating, especially if you feel like you're washing your hair all the time. This can be caused by a number of factors including hormones, diet, and product build-up.</p>
                          </div>
                        </div>
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input id="offers" name="offers" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                          </div>
                          <div className="ml-3 text-sm flex flex-col items-start">
                            <label htmlFor="offers" className="font-medium text-gray-700">
                              Breakage
                            </label>
                            <p className="text-gray-500">Breakage is one of the biggest challenges when it comes to hair care. It can be caused by a number of factors including over-styling, heat damage, and chemical damage.</p>
                          </div>
                        </div>
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input id="offers" name="offers" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                          </div>
                          <div className="ml-3 text-sm flex flex-col items-start">
                            <label htmlFor="offers" className="font-medium text-gray-700">
                              Frizzy
                            </label>
                            <p className="text-gray-500">Frizzy hair can be a challenge to manage, especially in humid weather. This can be caused by a number of factors including weather, product build-up, and over-washing.</p>
                          </div>
                        </div>
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input id="offers" name="offers" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                          </div>
                          <div className="ml-3 text-sm flex flex-col items-start">
                            <label htmlFor="offers" className="font-medium text-gray-700">
                              Shedding
                            </label>
                            <p className="text-gray-500">Excessive shedding and hair-loss can be a sign of an underlying health condition. It can also be caused by a number of factors including stress, diet, and certain medications.</p>
                          </div>
                        </div>
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input id="offers" name="offers" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                          </div>
                          <div className="ml-3 text-sm flex flex-col items-start">
                            <label htmlFor="offers" className="font-medium text-gray-700">
                              Dull
                            </label>
                            <p className="text-gray-500">Dull hair can be caused by a number of factors including weather, product build-up, and over-washing. It can also be a sign of an underlying health condition.</p>
                          </div>
                        </div>
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input id="offers" name="offers" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                          </div>
                          <div className="ml-3 text-sm flex flex-col items-start">
                            <label htmlFor="offers" className="font-medium text-gray-700">
                              Porous
                            </label>
                            <p className="text-gray-500">Porous hair can be caused by a number of factors including heat damage, chemical damage, and over-washing. This type of hair is more susceptible to damage and breakage.</p>
                          </div>
                        </div>
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input id="offers" name="offers" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                          </div>
                          <div className="ml-3 text-sm flex flex-col items-start">
                            <label htmlFor="offers" className="font-medium text-gray-700">
                              Heat Damage
                            </label>
                            <p className="text-gray-500">Heat damage is one of the biggest challenges when it comes to hair care. It can be caused by a number of factors including over-styling, flat-ironing, and blow-drying.</p>
                          </div>
                        </div>
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input id="offers" name="offers" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                          </div>
                          <div className="ml-3 text-sm flex flex-col items-start">
                            <label htmlFor="offers" className="font-medium text-gray-700">
                              Physical Damage /Braids, Weaves, Wigs/
                            </label>
                            <p className="text-gray-500">It can be caused by a number of factors including over-styling, tight hairstyles, and chemical damage.</p>
                          </div>
                        </div>
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input id="offers" name="offers" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                          </div>
                          <div className="ml-3 text-sm flex flex-col items-start">
                            <label htmlFor="offers" className="font-medium text-gray-700">
                              Over Processed Chemical Treatment
                            </label>
                            <p className="text-gray-500">Chemical damage is one of the biggest challenges when it comes to hair care. It can be caused by a number of factors including over-processing, bleaching, and perming.</p>
                          </div>
                        </div>
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input id="offers" name="offers" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                          </div>
                          <div className="ml-3 text-sm flex flex-col items-start">
                            <label htmlFor="offers" className="font-medium text-gray-700">
                              Color Damage
                            </label>
                            <p className="text-gray-500">This can be caused by a number of factors including over-processing, bleaching, and perming.</p>
                          </div>
                        </div>
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input id="offers" name="offers" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                          </div>
                          <div className="ml-3 text-sm flex flex-col items-start">
                            <label htmlFor="offers" className="font-medium text-gray-700">
                              Manageability/Tangled
                            </label>
                            <p className="text-gray-500">Dealing with unruly, unmanageable hair that is difficult to style.</p>
                          </div>
                        </div>
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input id="offers" name="offers" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                          </div>
                          <div className="ml-3 text-sm flex flex-col items-start">
                            <label htmlFor="offers" className="font-medium text-gray-700">
                              Thinning
                            </label>
                            <p className="text-gray-500">Thinning hair can be a sign of an underlying health condition. It can also be caused by a number of factors including stress, diet, and certain medications.</p>
                          </div>
                        </div>
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input id="offers" name="offers" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                          </div>
                          <div className="ml-3 text-sm flex flex-col items-start">
                            <label htmlFor="offers" className="font-medium text-gray-700">
                              Weak Edges
                            </label>
                            <p className="text-gray-500">Weak edges can be caused by a number of factors including over-styling, tight hairstyles, and chemical damage.</p>
                          </div>
                        </div>
                      </div>
                      <div className="sm:col-span-6 mb-8">
                        <button onClick={() => tabTo(2)} type="submit" className="bg-black hover:text-gray-200 text-white py-2 px-4 rounded-xl cursor-pointer w-full mt-10">
                          Next
                        </button>
                      </div>
                    </fieldset>
                  </form>
                </Fader>
              </TabPanel>
              <TabPanel>
                <Fader>
                  <div className="flex flex-1 opacity-100 flex-col items-center">
                    <fieldset className="flex items-start flex-col py-10">
                      <legend className="sr-only text-xl">What are your top 3 hair goals?</legend>
                      <div className="text-base font-medium text-gray-900" aria-hidden="true">
                        What are your top 3 hair goals?
                      </div>
                      <div className="mt-4 space-y-4 flex flex-col">
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input id="comments" name="comments" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                          </div>
                          <div className="ml-3 text-sm flex flex-col items-start">
                            <label htmlFor="comments" className="font-medium text-gray-700">
                              Curl Definition
                            </label>
                            <p className="text-gray-500">Having issues with your curls falling flat or losing their shape and texture throughout the day.</p>
                          </div>
                        </div>
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input id="candidates" name="candidates" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                          </div>
                          <div className="ml-3 text-sm flex flex-col items-start">
                            <label htmlFor="candidates" className="font-medium text-gray-700">
                              Length Retention
                            </label>
                            <p className="text-gray-500">Trying to grow your hair out but struggling with breakage and split ends.</p>
                          </div>
                        </div>
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input id="offers" name="offers" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                          </div>
                          <div className="ml-3 text-sm flex flex-col items-start">
                            <label htmlFor="offers" className="font-medium text-gray-700">
                              Retain Moisture
                            </label>
                            <p className="text-gray-500">Dealing with dry, brittle, or frizzy hair that doesn't seem to hold moisture no matter what you do.</p>
                          </div>
                        </div>
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input id="offers" name="offers" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                          </div>
                          <div className="ml-3 text-sm flex flex-col items-start">
                            <label htmlFor="offers" className="font-medium text-gray-700">
                              Increase Shine
                            </label>
                            <p className="text-gray-500">Looking for ways to add shine and luster to dull, lifeless hair.</p>
                          </div>
                        </div>
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input id="offers" name="offers" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                          </div>
                          <div className="ml-3 text-sm flex flex-col items-start">
                            <label htmlFor="offers" className="font-medium text-gray-700">
                              Healthy Heat Styling
                            </label>
                            <p className="text-gray-500">Trying to minimize heat damage from styling tools while still being able to achieve the look you want.</p>
                          </div>
                        </div>
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input id="offers" name="offers" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                          </div>
                          <div className="ml-3 text-sm flex flex-col items-start">
                            <label htmlFor="offers" className="font-medium text-gray-700">
                              Color Care
                            </label>
                            <p className="text-gray-500">Trying to maintain healthy hair color and prevent fading, brassiness, or other color issues.</p>
                          </div>
                        </div>
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input id="offers" name="offers" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                          </div>
                          <div className="ml-3 text-sm flex flex-col items-start">
                            <label htmlFor="offers" className="font-medium text-gray-700">
                              Manageability
                            </label>
                            <p className="text-gray-500">Dealing with unruly, unmanageable hair that is difficult to style.</p>
                          </div>
                        </div>
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input id="offers" name="offers" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                          </div>
                          <div className="ml-3 text-sm flex flex-col items-start">
                            <label htmlFor="offers" className="font-medium text-gray-700">
                              Stronger Hair
                            </label>
                            <p className="text-gray-500">Looking for ways to strengthen and fortify your hair to prevent breakage and promote healthy growth.</p>
                          </div>
                        </div>
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input id="offers" name="offers" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                          </div>
                          <div className="ml-3 text-sm flex flex-col items-start">
                            <label htmlFor="offers" className="font-medium text-gray-700">
                              Increase Fullness/Volume
                            </label>
                            <p className="text-gray-500">Trying to achieve fuller, thicker looking hair.</p>
                          </div>
                        </div>
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input id="offers" name="offers" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                          </div>
                          <div className="ml-3 text-sm flex flex-col items-start">
                            <label htmlFor="offers" className="font-medium text-gray-700">
                              Healthy Hair
                            </label>
                            <p className="text-gray-500">Overall concerns with maintaining healthy hair and preventing damage. Making your hair an important part of your self-care routine.</p>
                          </div>
                        </div>
                      </div>
                      <div className="sm:col-span-6 mb-8">
                        <button onClick={() => tabTo(3)} type="submit" className="bg-black hover:text-gray-200 text-white py-2 px-4 rounded-xl cursor-pointer w-full mt-10">
                          Next
                        </button>
                      </div>
                    </fieldset>
                  </div>
                </Fader>
              </TabPanel>

              <TabPanel>
                <Fader>
                  <div className="flex flex-1 opacity-100 flex-col items-center">
                    <fieldset className="flex items-start flex-col py-10">
                      <legend className="sr-only text-xl">What is most important to you when selecting hair care products?</legend>
                      <div className="text-base font-medium text-gray-900" aria-hidden="true">
                        What is most important to you when selecting hair care products?
                      </div>
                      <div className="mt-4 space-y-4 flex flex-col">
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input id="comments" name="comments" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                          </div>
                          <div className="ml-3 text-sm flex flex-col items-start">
                            <label htmlFor="comments" className="font-medium text-gray-700">
                              Natural Ingredients
                            </label>
                            <p className="text-gray-500">Having product that are not high in active chemicals.</p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4 space-y-4 flex flex-col">
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input id="comments" name="comments" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                          </div>
                          <div className="ml-3 text-sm flex flex-col items-start">
                            <label htmlFor="comments" className="font-medium text-gray-700">
                              Clean Ingredients
                            </label>
                            <p className="text-gray-500">Less complex ingredients.</p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4 space-y-4 flex flex-col">
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input id="comments" name="comments" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                          </div>
                          <div className="ml-3 text-sm flex flex-col items-start">
                            <label htmlFor="comments" className="font-medium text-gray-700">
                              Scientifically Advanced Formula
                            </label>
                            <p className="text-gray-500">Backed by science and reviewed.</p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4 space-y-4 flex flex-col">
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input id="comments" name="comments" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                          </div>
                          <div className="ml-3 text-sm flex flex-col items-start">
                            <label htmlFor="comments" className="font-medium text-gray-700">
                              Price
                            </label>
                            <p className="text-gray-500">The cost of a product plays a significant role in my purchase decisions.</p>
                          </div>
                        </div>
                      </div>
                      <div className="sm:col-span-6 mb-8">
                        <button onClick={() => tabTo(4)} type="submit" className="bg-black hover:text-gray-200 text-white py-2 px-4 rounded-xl cursor-pointer w-full mt-10">
                          Next
                        </button>
                      </div>
                    </fieldset>
                  </div>
                </Fader>
              </TabPanel>
              <TabPanel>
                <Fader>
                  <div className="flex flex-row gap-8">
                    <div className="flex flex-col">
                      <span className="py-4">1. Show only your hair within the frame</span>
                      <Webcam audio={false} screenshotFormat="image/png" style={{ height: 350, width: 350 }} videoConstraints={videoConstraints} className="overflow-hidden rounded-3xl -scale-x-100 bg-black">
                        {({ getScreenshot }) => (
                          <button
                            className="border-black border text-black rounded-xl m-2 p-4"
                            onClick={() => {
                              const imageSrc = getScreenshot();
                              setImageX(imageSrc);
                              classifyImage(imageSrc)
                                .then((result) => setExplainer(result.data))
                                .catch((ex) => console.warn(ex));
                            }}
                          >
                            Capture
                          </button>
                        )}
                      </Webcam>
                    </div>
                    <div className="flex flex-col">
                      <span className="py-4">2. Click "Capture" and Preview here</span>
                      <img src={imageX == "" ? SampleImg : imageX} style={{ height: 350, width: 350 }} className="overflow-hidden rounded-3xl -scale-x-100" />
                      <button className="bg-black text-white rounded-xl m-2 p-4" onClick={() => setSetIndex(5)}>
                        Upload
                      </button>
                    </div>
                  </div>
                </Fader>
              </TabPanel>
              <TabPanel>
                <Fader>
                  <div className="flex flex-col p-8 items-start w-full text-left overflow-y-auto">
                    <div className="">
                      <h1 className="text-xl font-bold leading-6 text-gray-900 py-1">The Results Are In</h1>
                      <div className="flex flex-row gap-2">
                        {Object.entries(explainer.score).map((key, val) => {
                          return (
                            <div className="text-sm font-bold bg-pink-500 py-1 px-4 rounded-lg text-white">
                              {key[0] == "NoHair" ? "Unscanned Amount" : key[0]} @ {key[1].toFixed(2)} %
                            </div>
                          );
                        })}
                      </div>
                      <p className="text-lg font-medium leading-6 text-gray-900 py-1">
                        Your hair texture is <b>{explainer.hairTextureLabel}</b> this is considered to be <b>{explainer.hairTypeLabel}</b>
                      </p>
                      <h3 className="text-lg font-medium leading-6 text-gray-900 py-1">
                        <b>Explanation:</b> {explainer.label}
                      </h3>
                      <h3 className="text-xl font-medium leading-6 py-1 text-white bg-black px-2 my-4">Based on these results we recommend the following products</h3>
                    </div>
                    <div className="overflow-y-auto w-full">
                      <div className="w-full">
                        {productsCSV.map((map) => {
                          if (map.TypeFor.includes(explainer.hairTypeLabel.split(",")[1].replace(" ", ""))) {
                            return (
                              <div className="bg-pink-200 m-2 w-56 p-5 rounded-xl inline-block">
                                <img src={map.ImageName == "" ? PlaceHolder : map.ImageName} alt="" srcset="" />
                                <div className="my-2">
                                  <div className="text-sm font-bold">{map.ProductName}</div>
                                  <div className="text-sm">{map.ProductClassification}</div>
                                  <span className="text-sm">{map.ProductType}</span>
                                  <div className="text-sm">for {map.HairChallenges}</div>
                                </div>
                                <div className="px-4 py-2 bg-pink-600 text-white rounded-md cursor-pointer" onClick={() => window.open(map.ProductLink, "_blank")}>
                                  Buy Now
                                </div>
                              </div>
                            );
                          } else {
                          }
                        })}
                      </div>
                    </div>
                  </div>
                </Fader>
              </TabPanel>
            </Tabs>
          </div>
        </div>
        <div className="h-14"></div>
        <div className="h-14 flex justify-end">
          <img src={poweredBy} className="h-10 lg:h-8 mx-auto lg:mx-0" />
        </div>
      </div>
    </div>
  );
}

export default App;
